

import React from 'react'

const ArenaView = () => {
  return (
    <div style={styles.root}>
        <div style={styles.shadow}></div>
        <img src="/assets/img/3d-arena.png" style={styles.image} />
    </div>
  )
}

const styles : { [key: string]: React.CSSProperties } = {
    root : {
        width : "220px",
        aspectRatio : 1,
        position : "relative"
    },
    shadow : {
        position : "absolute",
        width : "70%",
        height : "70%",
        top : "70px",
        left : "10px",
        backgroundColor : "#000000",
        opacity : "0.6",
        borderRadius : "12px",
        boxShadow : "0px 40px 35px 14px rgba(0,0,0,1)"
    },
    image : {
        width : "100%",
        height : "100%",
        zIndex : 1
    }
}

export default ArenaView