

import React, { useCallback, useContext, useEffect, useState } from 'react'
import app, { database } from '../helper/firebase'
import { onChildAdded, ref, get, off } from 'firebase/database'

const ChatContext = React.createContext<any>(null)


type ProviderProps = {
    children ?: any
}

const ChatProvider = (props : ProviderProps) => {

  const [chatlist, setChatlist] = useState<any[]>([])

  const fetchMessage = useCallback(async() => {
    try {
      const lastIndexRef = ref(database, "/chat/new/lastIndex")
      const lastIndex = parseInt((await get(lastIndexRef)).val())
      const list : any[] = []
      for(let i = Math.max(1, lastIndex - 20); i < lastIndex; i++ ){
        const chatRef = ref(database, "/chat/new/"+i)
        const message = (await get(chatRef)).val()
        list.push(message)
      }
      setChatlist(list)
    } catch (error) {
      console.log("fetchMessage", error)
    }
  }, [])

  const messageListener = useCallback(() => {
    const chatRef = ref(database, "/chat/new")
    return onChildAdded(chatRef, snapshot => {
      const data = snapshot.val()
      console.log("data", data)
      setChatlist(list => [...list, data])
    })
  }, [])

  const value = {
    chatlist
  }

  useEffect(() => {
    fetchMessage()
    messageListener()
    return () => {
    }
  }, [])

  return (
    <ChatContext.Provider value={value}>
        {props.children}
    </ChatContext.Provider>
  )
}


export const useChatContext = () => {
    const context = useContext(ChatContext)
    if(context){
        return context
    }
    return null
}

export default ChatProvider