

import React, { useState } from 'react'
import { useSpring, animated } from '@react-spring/web'

type TabBarProps = {
  selectedIndex : number,
  onChange : (index : number) => void
}

const TabBar = (props : TabBarProps) => {

  const [tab] = useState([
    {
      label : "About"
    },
    {
      label : "Skill"
    },
    {
      label : "Home"
    },
    {
      label : "Chat"
    },
    {
      label : "Blog"
    },
  ])

  const onPress = (index : number) => {
    props.onChange(index)
  }

  const renderItem = tab?.map((item, index) => <TabItem item={item} key={index} selected={index == props.selectedIndex} onPress={() => onPress(index)} /> , [])

  return (
    <div style={styles.root}>
      {renderItem}
    </div>
  )
}

type TabItemProps = {
  item : {
    label : string
  },
  selected : boolean
  onPress : () => void
}

const TabItem = (props : TabItemProps) => {

  const tabAnimation = useSpring({
    minWidth: props.selected ? "100px" : "65px",
    config: { duration: 100 }, // Adjust duration if needed
  })

  const fontAnimation = useSpring({
    fontSize: props.selected ? "18px" : "10px",
    config: { duration: 100 }, // Adjust duration if needed
  })

  return(
    <animated.div style={{...styles.tabItem, ...tabAnimation}} onClick={props.onPress}>
      <div style={styles.container}>
        <animated.span style={{...styles.label, ...fontAnimation}}>{props.item?.label}</animated.span>
      </div>
    </animated.div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        backgroundColor : "#56646f",
        minHeight : "72px",
        flexDirection : "row",
        boxShadow : "inset 0px 2px 8px 0px rgba(255,255,255,0.77)",
    },
    container : {
      justifyContent : "center"
    },
    tabItem : {
        border : "1px solid #ffffff20",
        borderRadius : "4px"
    },
    label : {
      color : "#fff",
      textAlign : "center"
    }
}

export default TabBar