

import React from 'react'


type ProgressBarProp = {
    theme : {
        primary ?: string
    }
}

const ProgressBar = (props : ProgressBarProp) => {
    const theme = props.theme
  return (
    <div style={styles.root}>
        <div style={{...styles.progress, backgroundColor : theme.primary}}>
            <div style={styles.progressHighlight}></div>
        </div>
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        backgroundColor : "#00000030",
        width : "100px",
        maxHeight : "18px",
        borderRadius : "4px",
        overflow : "hidden",
        padding : "2px",
        boxShadow : "rgb(255, 255, 255, 0.3) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.1) -3px -3px 6px 1px inset"
    },
    progress : {
        backgroundColor : "#b88311",
        flex : 1,
        borderRadius : "8px",
        padding : "2px",
        width : "40%"
    },
    progressHighlight : {
        backgroundColor : "#ffffff30",
        maxHeight : "35%",
        borderRadius : "2px"
    }
}

export default ProgressBar