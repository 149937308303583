
import { initializeApp } from 'firebase/app';
import {getDatabase} from "firebase/database"

// Replace with your Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyC-2_22gFZnkn8X8Bcu-5z8RIzkmLsY6tc",
  authDomain: "raeon-main.firebaseapp.com",
  databaseURL: "https://raeon-main-default-rtdb.firebaseio.com",
  projectId: "raeon-main",
  storageBucket: "raeon-main.firebasestorage.app",
  messagingSenderId: "108106103890",
  appId: "1:108106103890:web:02b6e36c95773e08db338b",
  measurementId: "G-X86T6EPK70"
};

const app = initializeApp(firebaseConfig);

export const database = getDatabase(app)
export default app;