

import React, { useMemo, useState } from 'react'

const HeaderTab = () => {

    const [tab, setTab] = useState([
        {
            label : "1"
        },
        {
            label : "2"
        },
        {
            label : "3"
        },
        {
            label : "4"
        },
        {
            label : "5"
        },
        {
            label : "6"
        },
        {
            label : "7"
        }
    ])

    const renderItem = tab.map((item, index) => <TabItem item={item} index={index} />, [])

    return (
        <div style={styles.root}>
            <div style={styles.subTabRoot}>
                {renderItem}
            </div>
        </div>
    )
}


type TabItemProps = {
    item : {
        label : string
    },
    index : number
}

const TabItem = (props : TabItemProps) => {
    return(
        <div style={styles.subTabItemRoot}>
            <div style={styles.subTabItemInner}></div>
            <span style={styles.subTabLabel}>{props.item?.label}</span>
        </div>
    )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        flex : 0
    },
    subTabRoot : {
        width : "360px",
        flexDirection : "row",
        maxHeight : "56px",
        gap : "12px",
        paddingInline : "12px",
        backgroundColor : "#1A43B4",
        alignItems : "center",
        boxShadow : "inset -3px 6px 37px -2px rgba(255,255,255,1)"
    },
    subTabItemRoot : {
        height : "36px",
        flexGrow : "unset",
        width : "36px",
        justifyContent : "center",
        alignItems : "center",
        backgroundColor : "#00000040",
        borderRadius : "8px"
    },
    subTabItemInner : {
        flex : 1,
        flexGrow : "unset",
        borderRadius : "8px",
        backgroundColor : "red"
    },
    subTabLabel : {
        color : "#fff",
        fontSize : "14px"
    }
}

export default HeaderTab