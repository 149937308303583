

import React from 'react'

const NameView = () => {
  return (
    <div style={styles.root}>
        <div style={styles.highlight}>
            <div style={styles.highlightDiv}></div>
        </div>
        <div style={styles.textRoot}>
            <span style={styles.title}>ALOK PRAKASH</span>
            <span style={styles.subTitle}>Software Developer</span>
        </div>
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        width : "85%",
        maxHeight : "44px",
        backgroundColor : "#00000030",
        borderTopRightRadius : "4px",
        borderBottomRightRadius : "8px",
        position : "relative",
        boxShadow : "rgb(0, 0, 0, 0.3) 3px 3px 6px 0px inset, rgba(0, 0, 0, 0.1) -3px -3px 6px 1px inset"
    },
    highlight : {
        position : "absolute",
        top : 0,
        left : 0,
        width : "100%",
        height : "48%"
    },
    highlightDiv : {
        backgroundColor : "#ffffff09", 
        marginRight : "8px",
        borderTopRightRadius : "6px",
        borderBottomRightRadius : "6px"
    },
    textRoot : {
        justifyContent : "center",
        paddingInline : "12px"
    },
    title : {
        fontSize : "12px",
        color : "#ffffff",
        marginBottom : "2px"
    },
    subTitle : {
        fontSize : "9px",
        color : "#41A9C7",
    }
}

export default NameView