import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppLoader from './pages/AppLoader';
import Home from './pages/Home';

function App() {
  return (
    <AppLoader>
      <Home />
    </AppLoader>
  );
}

export default App;
