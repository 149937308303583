

import React from 'react'
import ProgressBar from '../../../components/ProgressBar'
import ProgressBarIcon from '../../../components/ProgressBarIcon'
import Button from '../../../components/Button'
import ResumeButton from './components/ResumeButton'
import NameView from './components/NameView'
import ArenaView from './components/ArenaView'
import BattleBanner from './components/BattleBanner'

const MainView = () => {
  return (
    <div style={styles.root}>
        <div style={styles.headerRoot}>
            <div style={styles.countRoot}>
                <ProgressBarIcon />
                <ProgressBarIcon />
                <ProgressBarIcon />
            </div>
            <div>
                <div>
                    <div style={styles.nameContainerRoot}>
                        <BattleBanner />
                        <NameView />
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
        <div style={styles.contentRoot}>
            <div style={styles.stage}>
                <ArenaView />
            </div>
            <div>
                <ResumeButton width={"144px"} />
            </div>
        </div>
        <div style={styles.footerRoot}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        // background : "url('/assets/img/bg-texture.webp') center/cover no-repeat",
    },
    headerRoot : {
        height : "200px",
        background: "linear-gradient(180deg, #091c3b, #091c3b90, transparent)"
    },
    footerRoot : {
        height : "170px",
        background: "linear-gradient(0deg, #091c3b, #091c3b90, transparent)"
    },
    countRoot : {
        height : "60px",
        flexDirection : "row",
        alignItems : "center",
        gap : "16px",
        padding : "0px 16px 0px 16px"
    },
    nameContainerRoot : {
        width : "230px"
    },
    contentRoot : {
        alignItems : "center"
    },
    stage : {
        minWidth : "200px",
        maxHeight : "200px",
        marginBottom : "32px"
    }
}

export default MainView