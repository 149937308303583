

import React from 'react'
import ProgressBar from '../ProgressBar'

const ProgressBarIcon = () => {
  return (
    <div style={styles.root}>
        <div style={styles.imageRoot}>
            <div style={styles.labelContainer}>
                <img src="/assets/img/star.png" style={styles.image} />
                <span style={styles.font}>200/1000</span>
            </div>
        </div>
        <div style={styles.progressRoot}>
            <ProgressBar 
                theme={{
                    primary : "#41ac97"
                }} />
        </div>
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        maxHeight : "32px",
        maxWidth : "100px",
        flexDirection : "row",
        alignItems : "center"
    },
    progressRoot : {
        height : "18px"
    },
    labelContainer : {
        flexDirection : "row",
        alignItems : "center",
        maxHeight : "32px",
        position : "absolute",
        zIndex : 1,
        top : 0,
        left : 0
    },
    imageRoot : {
        width : "32px",
        height : "32px",
        position : "relative"
    },
    image : {
        width : "32px",
        height : "32px",
        animation: "spin 20s linear infinite"
    },
    font : {
        color : "#fff",
        fontSize : "8px",
        fontWeight : "900",
        marginLeft : "4px"
    }
}

export default ProgressBarIcon