

import React from 'react'
import Message from './Components/Message'
import ChatInput from './Components/ChatInput'
import ChatContainer from './Components/ChatContainer'

const ChatTab = () => {

  return (
    <div>
        <div style={styles.chatContainer}>
            <ChatContainer />
        </div>
        <div style={styles.inputContainer}>
            <ChatInput />
        </div>
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    chatContainer : {
        overflow : "scroll",
        alignItems : "flex-end",
        paddingBlock : "100px"
    },
    inputContainer : {
        flex : 0
    }
}

export default ChatTab