

import React from 'react'

const BattleBanner = () => {
  return (
    <div style={styles.root}>
        <div>
            <img src='/assets/img/battle-banner.png' style={styles.image} />
        </div>
    </div>
  )
}

const styles : { [key: string]: React.CSSProperties } = {
    root : {
        position : "relative",
        top : "2px",
        width : "100%",
        height : "72px",
        zIndex : 1
    },
    image : {
        width : "90%",
        height : "84px",
        position : "absolute",
        bottom : 0
    }
}

export default BattleBanner