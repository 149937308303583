

import React, { useState } from 'react'
import HeaderTab from './components/HeaderTab'
import SkillTabBody from './components/SkillTabBody'

const SkillTab = () => {

    const [skills] = useState([
        {
            label : "React.js"
        },
        {
            label : "Javascript"
        },
        {
            label : "Node.js"
        },
        {
            label : "React.js"
        },
        {
            label : "React.js"
        },
        {
            label : "React.js"
        },
        {
            label : "React.js"
        },
        {
            label : "React.js"
        }
    ])

  return (
    <div style={styles.root}>
        <HeaderTab />
        <SkillTabBody data={skills} />
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        paddingTop : "70px"
    }
}

export default SkillTab