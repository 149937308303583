

import React from 'react'

type MessageProps = {
    data : any,
    align ?: "left" | "right"
}

const Message = (props : MessageProps) => {
    const data = props.data
  return (
    <div style={props.align == "right" ? styles.right : styles.left}>
        <span style={styles.username}>~{data.username}</span>
        <span style={styles.body}>{data.message}</span>
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    left : {
        maxWidth : "264px",
        backgroundColor : "#fff",
        borderRadius : "0px 14px 14px 14px",
        alignSelf : "flex-start",
        padding : "8px 12px 8px 12px",
        flex : 0,
        marginTop : "12px",
        marginInline : "16px"
    },
    right : {
        maxWidth : "264px",
        backgroundColor : "#fff",
        borderRadius : "14px 0px 14px 14px",
        alignSelf : "flex-end",
        padding : "8px 12px 8px 12px",
        flex : 0,
        marginTop : "12px",
        marginInline : "16px"
    },
    username : {
        fontSize : "9px",
        color : "orange"
    },
    body : {
        fontSize : "10px",
        marginTop : "4px",
        lineHeight : "16px",
        color : "#000000"
    }
}

export default Message