

import React, { useEffect, useState } from 'react'
import { animated, useSpring, useSpringValue } from '@react-spring/web'
import Home from '../Home'

const AppLoader = ({children} : any) => {
    const [startAnim, setStartAnim] = useState(false)
    const [isAppReady, setIsAppReady] = useState(false)

    // const [springs, api] = useSpringValue()

    const backgroundAnimation = useSpring({
        delay : 0,
        to : {
            opacity : 1,
        },
        from : {
            opacity : 0,
        },
        config: { duration : 300 }
    })

    const mobileAnimation = useSpring({
        delay : startAnim ? 300 : 0,
        to : {
            opacity : 1,
            transform : "scale(0.9)"
        },
        from : {
            opacity : 0,
            transform : "scale(0.95)"
        },
        config: { duration : 2000 }
    })

    const iconAnimation = useSpring({
        delay :  1500,
        to : {
            opacity : 1
        },
        from : {
            opacity : 0
        },
        config: { duration : 500 }
    })

    useEffect(() => {
        setStartAnim(true)
        let timer = setTimeout(() => {
            setIsAppReady(true)
        }, 5000)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return(
        <animated.div style={{...styles.root, ...backgroundAnimation}}>
            <animated.div style={{...styles.mobileView, ...mobileAnimation}}>
                {
                    isAppReady ? children
                            :
                        (
                            <div style={styles.loaderRoot}>
                                <animated.img src='/assets/img/icon.webp' style={{...styles.icon, ...iconAnimation}} />
                            </div>
                        )
                }
            </animated.div>
        </animated.div>
    )
    
}

const styles : { [key: string]: React.CSSProperties } = {
    root : {
        flexDirection : "column",
        alignItems : "center",
        backgroundColor : "#000",
        opacity : 0
    },
    mobileView : {
        width : "360px",
        height : "720px",
        backgroundColor : "#ffffff20",
        borderRadius : "28px",
        border : "1px solid #ffffff30",
        WebkitBoxShadow: "0px 2px 120px 5px rgba(255,255,255,0.2)",
        MozBoxShadow: "0px 2px 120px 5px rgba(255,255,255,0.2)",
        boxShadow : "0px 2px 120px 5px rgba(255,255,255,0.2)",
        // transition : "transform 0.3s ease",
        overflow : "hidden"
    },
    loaderRoot : {
        justifyContent : "center",
        alignItems : "center",
        height : "70%"
    },
    icon : {
        width : "102px",
        aspectRatio : 1,
        borderRadius : "24px"
    }
}

export default AppLoader