

import React, { useEffect, useRef } from 'react'
import MainView from '../../Main'
import { useSpring, useSpringValue, animated } from '@react-spring/web'
import SkillTab from '../../Skill'
import ChatTab from '../../Chat'
import ChatProvider from '../../../../Context/ChatContext'

type TabBodyProps = {
    selectedIndex : number
}

const TabBody = (props : TabBodyProps) => {

    const scrollableRef = useRef<HTMLDivElement>(null);

    const [scrollAnimation, api] = useSpring(() => ({
        position : 0, // Initial scroll position
        config: { tension: 200, friction: 18 }, // Smooth animation
    }));

    const handleScroll = (index : number) => {
        if (scrollableRef.current) {
          const contentPosition = index * 360; // Scroll 200px down
        //   api.start({ scrollLeft : targetScrollTop }); // Trigger scroll animation
            api.start({position : contentPosition})
        }
    }

    const onScrollEnd = (event : any) => {
        // event.preventDefault()
        const left = event.target.scrollLeft
        const startPosition = props.selectedIndex * 360
        const endPosition = (props.selectedIndex + 1) * 360
        // console.log("event", startPosition, left, endPosition);
        // console.log("event", event);
        if(left > startPosition && left < endPosition){
            handleScroll(props.selectedIndex)
        }
    }

    useEffect(() => {
        handleScroll(props.selectedIndex)
    }, [props.selectedIndex])

  return (
    <animated.div 
        id="tab" 
        ref={scrollableRef} 
        style={styles.root}
        scrollLeft={scrollAnimation.position}
        onScrollCapture={onScrollEnd}
        // onScroll={onScrollEnd}
        >
        <div style={styles.tab}>
        </div>
        <div style={styles.tab}>
            <SkillTab />
        </div>
        <div style={styles.tab}>
            <MainView />
        </div>
        <div style={styles.tab}>
            <ChatProvider>
                <ChatTab />
            </ChatProvider>
        </div>
        <div style={styles.tab}>
        </div>
    </animated.div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        overflow : "hidden",
        flexDirection : "row"
    },
    tab : {
        minWidth : "360px",
        // border : "1px solid #000",
        background : "url('/assets/img/ui-tex-2.png') repeat",
        backgroundSize : "120px"
    }
}

export default React.memo(TabBody)