

import React from 'react'

type ResumeButtonProps = {
    width ?: string,
    height ?: string
}

const ResumeButton = (props : ResumeButtonProps) => {
  return (
    <div style={{...styles.root, width : props.width}}>
        <div style={styles.highlight}></div>
        <div style={styles.labelContainer}>
          <div style={styles.container}>
              <span style={styles.titleFont}>Resume</span>
              <span style={styles.subTitleFont}>Download</span>
              <span style={styles.thinFont}>November 2024</span>
          </div>
        </div>
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        height : "56px",
        backgroundColor : "#F8BB33",
        border : "1px solid #0000010",
        boxShadow : "inset 0px -18px 2px -10px rgba(0,0,0,0.13)",
        padding : "8px",
        borderRadius : "8px",
        position : "relative"
    },
    highlight : {
        backgroundColor : "#ffffff30",
        maxHeight : "50%",
        borderRadius : "8px"
    },
    labelContainer : {
      position : "absolute",
      width : "100%",
      height : "100%",
      top : 0,
      left : 0
    },
    container : {
      padding : "6px"
    },
    titleFont : {
      fontSize : "20px",
      textAlign : "center",
      fontWeight : "800",
      color : "#fff",
      textShadow : "0px 0px 2px rgba(24, 0, 0, 1)"
    },
    subTitleFont : {
      fontSize : "12px",
      textAlign : "center",
      fontWeight : "300",
      color : "#ffea00",
      textShadow : "0px 0px 2px rgba(24, 0, 0, 1)"
    },
    thinFont : {
      fontSize : "7px",
      textAlign : "center",
      fontWeight : "300",
      color : "#fff",
      textShadow : "0px 0px 2px rgba(24, 0, 0, 1)"
    }
}

export default ResumeButton