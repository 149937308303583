

import React, { useState } from 'react'
import TabBar from './components/TabView/TabBar'
import TabBody from './components/TabView/TabBody'

const Home = () => {

  const [currentIndex, setCurrentIndex] = useState(2)
  
  const onIndexChange = (index : number) => {
    setCurrentIndex(index)
  }

  return (
    <div style={styles.root}>
        <div>
            <TabBody selectedIndex={currentIndex} />
            <TabBar selectedIndex={currentIndex} onChange={onIndexChange} />
        </div>
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        backgroundColor : "#fff",
        flex : 1
    }
}

export default Home