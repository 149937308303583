

import React from 'react'

type SkillTabBodyProps = {
    data : any[]
}

const SkillTabBody = (props : SkillTabBodyProps) => {

    const renderItem = props.data?.map((item, index) => <SkillItem item={item} index={index} /> , [])

  return (
        <div style={styles.skillRoot}>
            {renderItem}
        </div>
  )
}

type SkillItemProps = {
    item : any,
    index : number
}

const SkillItem = (props : SkillItemProps) => {
    return(
        <div style={styles.skillItemRoot}>
            {props.item?.label}
        </div>
    )
}


const styles : { [key: string]: React.CSSProperties } = {
    skillRoot : {
        width : "356px",
        flexDirection : "row",
        flex : 0,
        flexWrap : "wrap",
        gap : "12px",
        marginInline : "12px"
    },
    skillItemRoot : {
        width : "75px",
        aspectRatio : 0.8,
        backgroundColor : "orange"
    }
}

export default SkillTabBody