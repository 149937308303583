

import React, { useMemo } from 'react'
import { useChatContext } from '../../../../Context/ChatContext'
import Message from './Message'

const ChatContainer = () => {
    const Chat = useChatContext()
    const renderItem = useMemo(() => {
        return Chat.chatlist?.map((item : any, index : number) => {
            return(
                <Message data={item} key={index} />
            )
        })
    }, [Chat.chatlist])
  return (
    <>
        {renderItem}
    </>
  )
}

export default ChatContainer