

import React from 'react'

const ChatInput = () => {
  return (
    <div style={styles.root}>
        <input style={styles.input} placeholder='Chat coming soon' disabled />
        {/* <div style={styles.button}></div> */}
    </div>
  )
}


const styles : { [key: string]: React.CSSProperties } = {
    root : {
        flexDirection : "row",
        height : "40px",
        margin : "8px",
        marginTop : "0px",
        backgroundColor : "#fff",
        borderRadius : "8px",
        overflow : "hidden"
    },
    input : {
        height : "40px",
        width : "100%",
        paddingInline : "12px",
        fontSize : "10px"
    },
    button : {
    }
}

export default ChatInput